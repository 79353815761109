import React, { Component } from "react"
import parsePhoneNumber from 'libphonenumber-js'
import tzOffset from 'tz-offset'
// import PropTypes from "prop-types"
import { Context } from '@shopify/app-bridge-react'
import { Redirect } from '@shopify/app-bridge/actions'
// import ShopifyRoutePropagator from "@shopify/react-shopify-app-route-propagator"
//import { Router } from "@reach/router"
import gql from "graphql-tag"
import { Query } from "react-apollo"
import {
    Button,
    Card,
    Layout,
    Banner,
    DataTable,
    Tabs,
    Caption,
    Spinner,
    ButtonGroup,
} from "@shopify/polaris"

import { withFirebase } from "../../providers/firebase"
import axios from "axios"

//import Settings from "./settings"
const dateFormatOptions = { year: 'numeric', month: 'numeric', day: 'numeric' };
// const timeFormatOptions = { hour: '2-digit', minute: '2-digit' };
const dateTimeFormatOptions = { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' };

const QUERIES = gql`
    query {
        shop {
            id
            name
            description
            email
            myshopifyDomain
            ianaTimezone
            plan {
                displayName
            }
        }
    }
`

class StoreInfo extends Component {
    static contextType = Context

    // static contextTypes = {
    //     polaris: PropTypes.object,
    // }

    state = {
        idToken: null,
        shopInfo: null,

        selectedMainTab: 0,
        selectedTab: 0,

        checkingIfInstalled: true,
        haveApiInstalled: false,
        billingIsActive: false,
        subscriptionPlan: null,
        hasActiveSubscription: null,

        appChargesLoading: true,
        appCharges: null,

        pickupLoading: false,
        pickupInStore: [],

        deliveriesLoading: false,
        deliveriesInStore: [],

        deliveredLoading: false,
        deliveredInStore: [],
    }

    isReady = false

    redirectAction = undefined

    componentDidMount() {
        this.prepareRedirect()
        this.loadSettings()
    }

    componentDidUpdate(_, prevState) {
        if (!this.isReady && prevState.idToken === null) {
            this.checkReadiness()
        }
        if (this.isReady && prevState.idToken === null && this.state.idToken !== null && this.state.haveApiInstalled) {
            this.getChargingState()
            this.getToPickup()
        }
    }

    async checkReadiness() {
        try {
            this.isReady = true
            const { firebase } = this.props
            const idToken = await firebase.auth().currentUser.getIdToken(true)
            this.setState({ idToken: idToken })
        } catch (error) {
            this.isReady = false
        }
    }

    prepareRedirect() {
        const app = this.context
        if (!this.redirectAction) {
            this.redirectAction = Redirect.create(app);
        }
    }

    redirectInternal(path, isRelative = true, isAdmin = true) {
        this.redirectAction.dispatch(isAdmin ? Redirect.Action.ADMIN_PATH : Redirect.Action.APP, {
            path: path,
            newContext: !isRelative,
        });
    }

    async loadSettings() {
        const { shop, firebase } = this.props
        if (!shop || !firebase) {
            return
        }
        const db = firebase.firestore()
        try {
            const shopRef = db.collection('shopifyShop').doc(shop)
            const shopDoc = await shopRef.get()
            if (shopDoc.exists) {
                const shopInfo = shopDoc.data()
                if (shopInfo.activatedAt && shopInfo.apiToken) {
                    this.setState({ haveApiInstalled: true, checkingIfInstalled: false })
                } else {
                    this.setState({ checkingIfInstalled: false })
                }
            }
        } catch (error) {
            this.setState({ checkingIfInstalled: false })
        }
    }

    async getChargingState() {
        const { firebase } = this.props
        this.setState({ appChargesLoading: true })
        try {
            const idToken = await firebase.auth().currentUser.getIdToken(true)
            if (idToken) {
                axios.get(`/api/recurrent_charge?auth=${idToken}`)
                    .then(result => {
                        this.setState({
                            appCharges: result.data.success ? result.data.charge : null,
                            billingIsActive: result.data.success ? result.data.charge.status === 'active' : false,
                        })
                    })
                    .catch(error => {
                        this.setState({ appCharges: null, billingIsActive: false })
                    })
                    .finally(() => {
                        this.setState({ appChargesLoading: false })
                    })
            }
        } catch (error) {
        }
    }

    formatAMPM = (date) => {
        let h = date.getHours();
        let minutes = date.getMinutes();
        let ampm = h >= 12 ? 'PM' : 'AM';
        h = h % 12;
        h = h ? h : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        return h + ':' + minutes + ' ' + ampm;
    }

    prepareDateTimeOptions(shopTimezone) {
        let localTimeFormatOptions = Object.assign({}, dateTimeFormatOptions)
        if (shopTimezone) {
            localTimeFormatOptions = Object.assign(localTimeFormatOptions, {timeZone: shopTimezone})
        }
        return localTimeFormatOptions
    }
    checkSameDate(date, timezone, check = new Date()) {
        const recordDate = tzOffset.timeAt(typeof date === 'string' ? new Date(date) : date, timezone)
        const filterStart = tzOffset.timeAt(typeof check === 'string' ? new Date(check) : check, timezone)
        const filterEnd = new Date(filterStart.getTime())
        filterStart.setHours(0, 0, 0, 0)
        filterEnd.setHours(24, 0, 0, 0)

        if (recordDate >= filterEnd) {
            return 1
        } else if (recordDate < filterStart) {
            return -1
        }
        return 0
    }

    getFilterDate(data, timezone, fieldName) {
        const { selectedMainTab } = this.state
        let nextDate = null
        const now = new Date()

        switch (selectedMainTab) {
            case 2:
                now.setDate(now.getDate() - 1)
                return now
            case 1:
                data.forEach(record => {
                    if ((this.checkSameDate(record[fieldName], timezone) > 0 && nextDate === null)
                        || (nextDate !== null && this.checkSameDate(record[fieldName], timezone, nextDate) < 0)) {
                            nextDate = record[fieldName]
                    }
                })
                return nextDate
            case 0:
            default:
                return now
        }
    }

    filterDeliveryDate(data, field, timezone) {
        return data.filter(item => {
            const recordDate = tzOffset.timeAt(new Date(item[field]), timezone)
            return this.checkDate(recordDate)
        })
    }

    renderPickupListItems(shopInfo) {
        if (!this.state || !this.state.pickupInStore) {
            return []
        }

        const filterDate = this.getFilterDate(this.state.pickupInStore, shopInfo.ianaTimezone, 'expectedPickup')
        if (filterDate === null) {
            return []
        }

        return this.state.pickupInStore
            .filter(record => this.checkSameDate(record.expectedPickup, shopInfo.ianaTimezone, filterDate) === 0)
            .map(record => {
                const localTimeFormatOptions = this.prepareDateTimeOptions(shopInfo.ianaTimezone)
                const orderCreatedAt = new Date(Date.parse(record.externalRef.orderCreatedAt))
                const expectedPickup = new Date(Date.parse(record.expectedPickup))

                const orderCreatedAtShopDate = tzOffset.timeAt(orderCreatedAt, shopInfo.ianaTimezone)

                const pickupFromShopDate = tzOffset.timeAt(expectedPickup, shopInfo.ianaTimezone)

                const pickupToShopDate = new Date(pickupFromShopDate.getTime())
                const weekDay = pickupFromShopDate.getDay()
                if (weekDay > 0 && weekDay < 6) {
                    pickupToShopDate.setHours(record.defs.pickupEndWeek, 0, 0)
                } else {
                    pickupToShopDate.setHours(record.defs.pickupEndWeekend, 0, 0)
                }

                return [
                    <div>
                        {record.externalRef.orderName}<Caption>{orderCreatedAtShopDate.toLocaleString(undefined, dateTimeFormatOptions)}</Caption>
                    </div>,
                    <div>
                        {record.flyper.name}<Caption>{record.flyper.id_number}</Caption>
                    </div>,
                    <div>
                        {record.pickupLocation.name}<Caption>{pickupFromShopDate.toLocaleString(undefined, dateFormatOptions)} {/*this.formatAMPM(pickupFromShopDate)*/} {this.formatAMPM(pickupToShopDate)}</Caption>
                    </div>,
                    <div>
                        {record.recipient.name}
                        <Caption>{`${record.recipient.address}, ${record.recipient.postalCode} ${record.recipient.location}`}</Caption>
                    </div>,
                    <div>
                        {record.externalRef.orderItems.map(item => {
                            return <Caption>{`${item.quantity} x ${item.name}`}</Caption>
                        })}
                    </div>,
                ]
            })
    }

    renderDeliverListItems(shopInfo) {
        if (!this.state || !this.state.deliveriesInStore) {
            return []
        }

        const filterDate = this.getFilterDate(this.state.deliveriesInStore, shopInfo.ianaTimezone, 'expectedDeliver')
        if (filterDate === null) {
            return []
        }

        return this.state.deliveriesInStore
            // .filterDeliveryDate(this.state.deliveriesInStore, 'expectedDeliver', shopInfo.ianaTimezone)
            .filter(record => this.checkSameDate(record.expectedDeliver, shopInfo.ianaTimezone, filterDate) === 0)
            .map(record => {
                const localTimeFormatOptions = this.prepareDateTimeOptions(shopInfo.ianaTimezone)
                const orderCreatedAt = new Date(Date.parse(record.externalRef.orderCreatedAt))
                const expected = new Date(Date.parse(record.expectedDeliver))

                const shopDate = tzOffset.timeAt(expected, shopInfo.ianaTimezone)

                const toShopDate = new Date(shopDate.getTime())
                const weekDay = shopDate.getDay()
                if (weekDay > 0 && weekDay < 6) {
                    shopDate.setHours(record.defs.deliverStartWeek, 0, 0)
                    toShopDate.setHours(record.defs.deliverEndWeek, 0 ,0)
                    // toShopDate.setHours(23, 0, 0)
                } else {
                    shopDate.setHours(record.defs.deliverStartWeekend, 0, 0)
                    toShopDate.setHours(record.defs.deliverEndWeekend, 0 ,0)
                    // toShopDate.setHours(23, 0, 0)
                }

                return [
                    <div>
                        {record.externalRef.orderName}<Caption>{orderCreatedAt.toLocaleDateString(undefined, localTimeFormatOptions)}</Caption>
                    </div>,
                    <div>
                        {record.flyper.name}<Caption>{record.flyper.phone ? parsePhoneNumber(record.flyper.phone).formatInternational(): ''}</Caption>
                    </div>,
                    <div>
                        {record.recipient.address}<Caption>{shopDate.toLocaleString(undefined, dateFormatOptions)} {this.formatAMPM(shopDate)} -- {this.formatAMPM(toShopDate)}</Caption>
                    </div>,
                    <div>
                        {record.externalRef.orderItems.map(item => {
                            return <Caption>{`${item.quantity} x ${item.name}`}</Caption>

                        })}
                    </div>,
                ]
            })
    }

    renderDeliveredListItems(shopInfo) {
        if (!this.state || !this.state.deliveredInStore) {
            return []
        }

        const filterDate = this.getFilterDate(this.state.deliveredInStore, shopInfo.ianaTimezone, 'deliveredAt')
        if (filterDate === null) {
            return []
        }

        return this.state.deliveredInStore
            // .filterDeliveryDate(this.state.deliveredInStore, 'deliveredAt', shopInfo.ianaTimezone)
            .filter(record => this.checkSameDate(record.deliveredAt, shopInfo.ianaTimezone, filterDate) === 0)
            .map(record => {
                const localTimeFormatOptions = this.prepareDateTimeOptions(shopInfo.ianaTimezone)
                const orderCreatedAt = new Date(Date.parse(record.externalRef.orderCreatedAt))
                const expected = new Date(Date.parse(record.deliveredAt))

                const shopDate = tzOffset.timeAt(expected, shopInfo.ianaTimezone)

                return [
                    <div>
                        {record.externalRef.orderName}<Caption>{orderCreatedAt.toLocaleDateString(undefined, localTimeFormatOptions)}</Caption>
                    </div>,
                    <div>
                        {record.flyper.name}<Caption>{record.flyper.phone ? parsePhoneNumber(record.flyper.phone).formatInternational() : ''}</Caption>
                    </div>,
                    <div>
                        {record.recipient.address}<Caption>{shopDate.toLocaleDateString(undefined, localTimeFormatOptions)}</Caption>
                    </div>,
                    <div>
                        {record.externalRef.orderItems.map(item => {
                            return <Caption>{`${item.quantity} x ${item.name}`}</Caption>

                        })}
                    </div>,
                ]
            })
    }

    async getToPickup() {
        const { firebase } = this.props
        this.setState({ pickupLoading: true })
        try {
            const idToken = await firebase.auth().currentUser.getIdToken(true)
            if (idToken) {
                axios.get(`/api/pickups?auth=${idToken}`)
                    .then(result => {
                        if (result.data.success) {
                            this.setState({ pickupInStore: result.data.records })
                        }
                    })
                    .catch(error => {
                        this.setState({ pickupInStore: [] })
                    })
                    .finally(() => {
                        this.setState({ pickupLoading: false })
                    })
            }
        } catch (error) {
        }
    }

    async getToDeliver() {
        const { firebase } = this.props
        this.setState({ deliveriesLoading: true })

        try {
            const idToken = await firebase.auth().currentUser.getIdToken(true)
            if (idToken) {
                axios.get(`/api/deliveries?auth=${idToken}`)
                    .then(result => {
                        if (result.data.success) {
                            this.setState({ deliveriesInStore: result.data.records })
                        }
                    })
                    .catch(error => {
                        this.setState({ deliveriesInStore: [] })
                    })
                    .finally(() => {
                        this.setState({ deliveriesLoading: false })
                    })
            }
        } catch (error) {
        }
    }

    async getToDelivered() {
        const { firebase } = this.props
        this.setState({ deliveredLoading: true })

        try {
            const idToken = await firebase.auth().currentUser.getIdToken(true)
            if (idToken) {
                axios.get(`/api/delivered?auth=${idToken}`)
                    .then(result => {
                        if (result.data.success) {
                            this.setState({ deliveredInStore: result.data.records })
                        }
                    })
                    .catch(error => {
                        this.setState({ deliveredInStore: [] })
                    })
                    .finally(() => {
                        this.setState({ deliveredLoading: false })
                    })
            }
        } catch (error) {
        }
    }

    billingApprovalStatus() {
        if (this.state.appChargesLoading) {
            return null
        }
        const { appCharges } = this.state
        const { confirmation_url, update_capped_amount_url } = appCharges

        let relativePath = false
        let confirmationPath = ''
        let fullUrl = ''
        if (confirmation_url) {
            fullUrl = confirmation_url
        } else if (update_capped_amount_url) {
            fullUrl = update_capped_amount_url
        }
        if (fullUrl) {
            const confirmationUrl = new URL(fullUrl)
            const toRemoveFromUrl = `${confirmationUrl.protocol}//${confirmationUrl.hostname}/admin`
            relativePath = fullUrl.indexOf(toRemoveFromUrl) === 0
            confirmationPath = fullUrl.replace(toRemoveFromUrl, '')
        }

        switch (appCharges.status) {
            case 'active':
            case 'accepted':
                return null
            case 'pending':
            default:
                return (
                    <Banner status="critical" title="Billing missing">
                        <div style={{display:'flex', flexDirection: 'column', flexWrap: 'nowrap', justifyContent: 'space-between', alignItems: 'flex-end'}}>
                            <p>The Flype service requires the acceptance of a maximum monthly charge allowance. By default it will be set to an amount but you can change that amount freely.</p>
                            {appCharges?.confirmation_url ? (
                                <Button onClick={() => this.redirectInternal(relativePath ? confirmationPath : fullUrl, relativePath)}>Go to approval</Button>
                            ): (
                                <Button url="/app/settings/">Go settings</Button>
                            )}
                        </div>
                    </Banner>
                )
        }
    }

    mainTabSelection(tabIndex) {
        if (this.state.selectedMainTab !== tabIndex) {
            this.setState({
                selectedMainTab: tabIndex,
                selectedTab: 0
            }, () => {
                this.tabSelection(0)
            })
        }
    }

    tabSelection(tabIndex) {
        const { selectedMainTab } = this.state

        const getToPickup = () => this.getToPickup();
        const getToDeliver = () => this.getToDeliver();
        const getToDelivered = () => this.getToDelivered();

        const actionsMap = [
            [getToPickup, getToDeliver, getToDelivered],
            [getToPickup, getToDeliver, getToDelivered],
            [getToPickup, getToDeliver, getToDelivered],
        ]

        actionsMap[selectedMainTab][tabIndex]()
        this.setState({ selectedTab: tabIndex })
    }

    renderUnderTabs() {
        const { selectedMainTab, selectedTab } = this.state
        const actions = [{
            id: 'pickup',
            content: 'To pick up',
        },{
            id: 'deliver',
            content: 'To deliver',
        },{
            id: 'delivered',
            content: 'Delivered',
        }]

        const tabs = [
            [0, 1, 2],
            [0, 1, 2],
            [0, 1, 2],
        ]

        const displayTabs = tabs[selectedMainTab].map((actionIdx, idx) => (
            <Button primary={selectedTab === idx} onClick={() => this.tabSelection(idx)} size="slim">
                {actions[actionIdx].content}
            </Button>
        ))

        const rendered = (
            <div style={{display: 'flex', justifyContent: 'flex-start', marginLeft: 12, marginRight: 12, marginTop: 15}}>
                <ButtonGroup segmented={displayTabs.length > 1} connectedTop={true}>
                    {displayTabs}
                </ButtonGroup>
            </div>
        )

        return rendered
    }

    renderTabContent(shopInfo = {}) {
        const {
            selectedMainTab,
            selectedTab,
            pickupLoading,
            deliveriesLoading,
            deliveredLoading,
        } = this.state
        const colTypes = [
            ['text', 'text', 'text', 'text', 'text' ],
            ['text', 'text', 'text', 'text' ],
            ['text', 'text', 'text', 'text' ]
        ]
        const headings = [
            [ 'Order', 'Flyper', 'Pickup', 'Recipient', 'Details' ],
            [ 'Order', 'Flyper', 'Delivery', 'Details' ],
            [ 'Order', 'Flyper', 'Delivered', 'Details' ],
        ]
        const data = [
            [
                () => this.renderPickupListItems(shopInfo),
                () => this.renderDeliverListItems(shopInfo),
                () => this.renderDeliveredListItems(shopInfo)
            ],
            [
                () => this.renderPickupListItems(shopInfo),
                () => this.renderDeliverListItems(shopInfo),
                () => this.renderDeliveredListItems(shopInfo)
            ],
            [
                () => this.renderPickupListItems(shopInfo),
                () => this.renderDeliverListItems(shopInfo),
                () => this.renderDeliveredListItems(shopInfo)
            ],
        ]
        const loading = [
            [ pickupLoading, deliveriesLoading, deliveredLoading ],
            [ pickupLoading, deliveriesLoading, deliveredLoading ],
            [ pickupLoading, deliveriesLoading, deliveredLoading ],
        ]

        return loading[selectedMainTab][selectedTab]
            ?   <div style={{textAlign: 'center'}}><Spinner /></div>
            : (
                <DataTable
                    columnContentTypes={colTypes[selectedTab]}
                    headings={headings[selectedTab]}
                    initialSortColumnIndex={3}
                    sortable
                    rows={data[selectedMainTab][selectedTab]()}
                />
            )
    }

    render() {
        const {
            selectedMainTab,
            checkingIfInstalled,
            appChargesLoading,
            haveApiInstalled,
            billingIsActive,
        } = this.state

        const mainTabs = [{
            id: 'today',
            content: 'Today',
            panelID: 'today-info',
        },{
            id: 'upcoming',
            content: 'Upcoming',
            panelID: 'upcoming-info',
        },{
            id: 'yesterday',
            content: 'Yesterday',
            panelID: 'yesterday-info',
        }]

        return (
            <div>
                <Query query={QUERIES}>
                    {({ loading, error, data }) => {
                        const shop = data && data.shop
                        // TODO: pass page title for <Page> component in layout to page layout context API
                        return (
                            <Layout>
                                <Layout.Section>
                                    {!checkingIfInstalled && !haveApiInstalled ? (
                                    <Banner status="info"
                                        title="Flype is currently disabled">
                                            <div style={{display:'flex', flexWrap: 'nowrap', justifyContent: 'space-between', alignItems: 'center'}}>
                                                <p>Make sure you have a valid Flype API Token</p>
                                                <Button url="/app/settings/">Update API Token</Button>
                                            </div>
                                    </Banner>
                                    ) : null}
                                    {haveApiInstalled ? this.billingApprovalStatus() : null}
                                </Layout.Section>
                                {haveApiInstalled && billingIsActive ? (
                                    <Layout.Section>
                                        <Card>
                                            <Tabs tabs={mainTabs} selected={selectedMainTab} onSelect={(tabIndex) => this.mainTabSelection(tabIndex)}>
                                                {this.renderUnderTabs()}
                                                <Card.Section>
                                                    {this.renderTabContent(shop)}
                                                </Card.Section>
                                            </Tabs>
                                        </Card>
                                    </Layout.Section>
                                ) : checkingIfInstalled || (haveApiInstalled && appChargesLoading) ? (
                                    <Layout.Section>
                                        <div style={{textAlign: 'center'}}>
                                            <Spinner />
                                        </div>
                                    </Layout.Section>
                                ) : null}
                            </Layout>
                        )
                    }}
                </Query>
            </div>
        )
    }
}

export default withFirebase(StoreInfo)
